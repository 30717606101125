import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import logo from "../images/logo.svg"
import styles from "../styles/Header.module.scss"

const Header = () => {
  const [burgerMenu, setBurgerMenu] = useState(false) 

  return (
    <>
      <header className={`${styles.headerContainer}`}>
        <div onClick={() => {setBurgerMenu(!burgerMenu)}} className={styles.burgerMenu}>
          <div className={styles.linesContainer}>
            <span className={`${styles.burgerMenuLine} ${burgerMenu ? styles.active : ""}`}></span>
            <span className={`${styles.burgerMenuLine} ${burgerMenu ? styles.active : ""}`}></span>
            <span className={`${styles.burgerMenuLine} ${burgerMenu ? styles.active : ""}`}></span>
          </div>
        </div>
        <div className={`${styles.header} container`}>
          <div className={styles.logoContainer}>
            <Link to={"/"} className={styles.logo}>
              <img src={logo} alt="Логотип" />
            </Link>
            <p className={styles.slogan}>
              Откройте для себя вкус настоящей Грузии с нашим сыром!
            </p>
          </div>
        </div>

        <div className={`${styles.navContainer} `}>
          <nav className={`${styles.nav} ${burgerMenu ? styles.active : ""} container`}>
            <div className={styles.linkContainer}>
              <NavLink to={"/"} className={styles.navLink}>
                Главная
              </NavLink>
              <NavLink to={"/order"} className={styles.navLink}>
                Сделать заказ
              </NavLink>
              <NavLink to={"/feedback"} className={styles.navLink}>
                Обратная связь
              </NavLink>
              <NavLink to={"/contacts"} className={styles.navLink}>
                Контакты
              </NavLink>
            </div>
            <a href="tel:+79267871112" className={styles.phone}>+7 (926) 787-11-12</a>
          </nav>
        </div>
      </header>
    </>
  )
}

export {Header};