import styles from "../styles/AboutUs.module.scss"
import fermer from "../images/fermer.png"
import team from "../images/team.png"
import cheeses from "../images/cheeses.png"
import upgrade from "../images/upgrade.png"

const AboutUs = () => {

  return (
    <>
      <div className={styles.background}>
        <div className={`${styles.aboutUsContainer} container`}>
          <h2 className={styles.heading}>
            О компании
          </h2>
          <ul className={styles.list}>
            <li className={styles.item}>
              <img className={styles.img} src={""} alt="" />
              <p className={styles.text}>
                Наша компания гордится тем, что мы работаем только с высококачественными ингредиентами, чтобы создавать аутентичные и вкусные сыры. Мы тщательно отбираем сырную закваску и используем только натуральные методы созревания, чтобы каждый наш сыр имел превосходный вкус и текстуру.
              </p>
            </li>
            <li className={styles.item}>
              <img className={styles.img} src={cheeses} alt="" />
              <p className={styles.text}>
                Мы предлагаем широкий ассортимент различных сортов сыра, позволяя нашим клиентам выбирать и наслаждаться разнообразием вкусов. Наша команда менеджеров всегда готова помочь вам выбрать и подобрать идеальный сыр под ваши предпочтения и потребности. Независимо от того, ищете ли вы что-то мягкое и кремообразное или же предпочитаете более интенсивный и острый вкус, мы гарантируем, что у нас есть сыр, который подойдет именно вам.
              </p>
            </li>
            <li className={styles.item}>
              <img className={styles.img} src={team} alt="" />
              <p className={styles.text}>
                Команда квалифицированных сыроделов и экспертов обеспечивает строгий контроль качества на каждом этапе производства сыра. Они следят за тем, чтобы все ингредиенты были высокого качества и соответствовали установленным стандартам. Все этапы производства, начиная с подготовки сырного зерна и заканчивая его экспозицией, контролируются, чтобы гарантировать получение сыра оптимального вкуса и текстуры. Такой подход позволяет производителям сыра достичь высоких стандартов и удовлетворить требования самых взыскательных потребителей.
              </p>
            </li>
            <li className={styles.item}>
              <img className={styles.img} src={fermer} alt="" />
              <p className={styles.text}>
                Мы также активно сотрудничаем с местными фермерами и производителями молока, поддерживая устойчивое сельское хозяйство и гарантируя свежесть и натуральность нашей продукции. Это важно для нас, потому что мы верим в значение качественной и здоровой пищи для наших потребителей. Кроме того, наши партнеры фермеры и производители молока делятся нашим стремлением к натуральным и свежим продуктам, которые мы предлагаем нашим клиентам.
              </p>
            </li>
            <li className={styles.item}>
              <img className={styles.img} src={upgrade} alt="" />
              <p className={styles.text}>
                Компания постоянно ищет новые способы улучшить свои производственные процессы и удовлетворить потребности и предпочтения наших клиентов. Мы внимательно следим за изменениями в отрасли и внедряем передовые технологии для повышения эффективности нашего производства. Мы также активно изучаем потребности и предпочтения наших клиентов, чтобы наши продукты и услуги отвечали их высоким требованиям и ожиданиям. Наша цель - обеспечить качественное производство и постоянно совершенствовать свои процессы.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
   )
 }

export {AboutUs};