import { Outlet } from 'react-router-dom'
import { Header } from './Header'
import { Footer } from './Footer'
import { FeedBackModal } from './modal/FeedbackModal'

const Layout = () => {

  return (
    <>
      <Header/>
      <Outlet />
      <Footer/>
      <FeedBackModal />
    </>
  )
}

export {Layout};