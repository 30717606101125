import { NavLink } from "react-router-dom"
import logoFooter from "../images/logo-footer.png"
import logo from "../images/logo.svg"
import styles from "../styles/Footer.module.scss"

const Footer = () => {

    return (
      <>
        <footer className={styles.footer}>
          <div className={`${styles.footerContainer} container`}>
            <div className={styles.footerTop}>
              <nav className={styles.nav}>
                <div className={styles.linkContainerLeft}>
                  <NavLink to={"/"} className={styles.navLink}>
                    Главная
                  </NavLink>
                  <NavLink to={"/order"} className={styles.navLink}>
                    Сделать заказ
                  </NavLink>
                  <NavLink to={"/feedback"} className={styles.navLink}>
                    Обратная связь
                  </NavLink>
                  <NavLink to={"/contacts"} className={styles.navLink}>
                    Контакты
                  </NavLink>
                </div>
              </nav>
              <a href="tel:+79267871112" className={styles.phone}>+7 (926) 787-11-12</a>
            </div>
            <div className={styles.footerBottom}>
              <img className={styles.logo} src={logo} alt="Логотип" />
              <p className={styles.footerBottomText}>
                Традиции и качество<br />в каждом ломтике нашего сыра
              </p>
            </div>
            <img className={styles.footerLogo} src={logoFooter} alt="" />
          </div>
        </footer>
        <div className={`${styles.copyRightContainer} container`}>
          <p className={styles.copyRightText}>
            © 2023 Любое использование контента без письменного разрешения запрещено
          </p>
        </div>
      </>
    )
  }
  
  export {Footer};