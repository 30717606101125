import styles from "../styles/Contacts.module.scss"

const Contacts = () => {

  return (
    <>
      <div className={`${styles.contactsContainer} container`}>
        <h2 className={styles.heading}> 
          Контакты компании "Kavkaz Cheese"
        </h2>
        <address>
          <p className={styles.telContainer}>
            Телефон:&nbsp;
            <a href="tel:+79267871112">
              +7 (926) 787-11-12
            </a>
          </p>
          <p className={styles.mailContainer}>
            Почта:&nbsp;
            <a href="mailto:gabis04@mail.ru">
              gabis04@mail.ru
            </a>
          </p>
          <p className={styles.addressText}>
            Адрес:&nbsp; Капотня 5кв-л д16 кв16
          </p>
          <div className={styles.mapContainer}>
            <iframe className={styles.map} src="https://yandex.ru/map-widget/v1/?um=constructor%3A37b9a8bb69f564788f51580963eb7b9f5b4ed478d64d9848e56dd928d080c5a1&amp;source=constructor" width="100%" height="531" frameborder="0"></iframe>
          </div>
        </address>
      </div>  
    </>
  )
}

export {Contacts};