import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import styles from "../../styles/FeedBackModal.module.scss"

const FeedBackModal = () => {

  const location = useLocation();
  const [isTime, setIsTime] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false)
  const [isPulse, setIsPulse] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (location.pathname !== "/order" && location.pathname !== "/feedback") {
        setIsTime(true)
        setIsPageLoaded(true)
        const timer = setTimeout(() => setIsPulse(false), 1600);
        return () => clearTimeout(timer);
      }
    }, 6000);
    return () => clearTimeout(timer);
  });
  
  const scrollToTop = () => {
    window.scrollTo(0,0)
  }

  useEffect(() => {
    if (isPageLoaded && location.pathname !== "/order" && location.pathname !== "/feedback") {
      const timer = setTimeout(() => setIsTime(true), 2000);
      return () => clearTimeout(timer);
    } else {
      setIsTime(false)
    }
  }, [location, isPageLoaded]);

  return (
    <>
      {/* {location.pathname === "/order" || location.pathname === "/feedback" ? (
        <></>
      ) : (
      )} */}
        <Link onClick={scrollToTop} to={"/order"} className={`${styles.container} ${isTime ? (styles.active) : ("")} ${isPulse ?(styles.pulse) : ("")}`}>
          <p  className={styles.text}>
            Оставить заявку
          </p>
        </Link>  
    </>
  )
}

export {FeedBackModal};