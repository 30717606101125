import { useState } from "react";
import emailjs from 'emailjs-com';
import { Forms } from "./Forms";
import { OrderLoadingModal } from "./modal/OrderLoadingModal";
import styles from "../styles/Feedback.module.scss"
import ReCAPTCHA from "react-google-recaptcha"

const Feedback = () => {

  const key = '6Ld9ybYoAAAAAKodYOh24WJC1p2MVHb6FtbGW6I8'
  const key2 = '6Le9w78oAAAAAECea2j6jRBJfvY6opICD5_2TO5u'
  const localKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'

  const [isDisabled, setIsDisabled] = useState(true)
  const [modalLoading, setModalLoading] = useState(false)
  const [count, setCount] = useState("")
  const [isComplete, setIsComplete] = useState(false)

  const [isCaptcha, setIsCaptcha] = useState(true)

  const onChange = () => {
    setIsCaptcha(false)
  }

  const handleDisabled = (isValidForm) => {
    if (isValidForm || count === 0 || count === "" 
    || isCaptcha
    ) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }

  const unMask = () => {
    var mask = document.getElementById('phone')
    var withoutMask = mask.value.replace(/[-()\s]/g, '');
    mask.value = withoutMask
  }

  const sendEmail = (e) => {
    e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
    setModalLoading(true);
    emailjs.sendForm('service_tv31t69', 'template_b0l5gsn', e.target, 'xFv6xnHHJm9cx44Uc')
      .then((result) => {
        setIsComplete(true)
      }, (error) => {
        console.log(error.text);
      });
  }

  return (
    <>
      <div className={`${styles.feedbackContainer} container`}>
        <OrderLoadingModal modalLoading={modalLoading} isComplete={isComplete}/>
        <h2 className={styles.feedbackHeading}>
          Обратная связь
        </h2>
        <form
          className={styles.form}
          onSubmit={(e) => {
            sendEmail(e)
          }}
        >
          <Forms validFunction={handleDisabled}/>
          <textarea onChange={e => setCount(e.target.value.length)} placeholder="Ваши предложения для сотрудничества..." id="comment" className={styles.comment} name="comment" cols="30" rows="10" />
          
          <div className={styles.captchaContainer}>
            <ReCAPTCHA
              sitekey={key2}
              onChange={onChange}
            />
          </div>

          <button
            onClick={() => {
              unMask();
            }}
            disabled={isDisabled}
            className={styles.button}
            type="submit"
          >
            Отправить
          </button>
        </form>
      </div>
    </>
  )
}

export {Feedback};