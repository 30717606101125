import { Order } from "../components/Order";


const TakeOrderPage = () => {

  return (
    <>
      <Order />        
    </>
  )
}

export {TakeOrderPage};