import { KavkazCheese } from "../components/KavkazCheese";
import { AboutUs } from "../components/AboutUs";
import { Quality } from "../components/Quality";

const HomePage = () => {

  return (
    <>
      <KavkazCheese />
      <AboutUs />
      <Quality />
    </>
  )
}

export {HomePage};