import { Feedback } from "../components/Feedback";

const FeedbackPage = () => {

  return (
    <>
      <Feedback />
    </>
  )
}

export {FeedbackPage};