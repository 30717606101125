import { Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout";
import { HomePage } from "./pages/HomePage";
import { TakeOrderPage } from "./pages/TakeOrderPage";
import { ContactsPage } from "./pages/ContactsPage";
import { OfertaPage } from "./pages/OfertaPage";
import { UserAgreementPage } from "./pages/UserAgreementPage";
import { FeedbackPage } from "./pages/FeedbackPage";
import "./styles/active.scss"
import "./styles/index.scss"

const App = () => {
  return (
    <>    
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<HomePage/>}/>
          <Route path="/order" element={<TakeOrderPage/>}/>
          <Route path="/feedback" element={<FeedbackPage/>}/>
          <Route path="/contacts" element={<ContactsPage/>}/>
          <Route path="/oferta" element={<OfertaPage/>}/>
          <Route path="/user-agreement" element={<UserAgreementPage/>}/>
        </Route>
      </Routes>
    </>
  );
}

export {App};