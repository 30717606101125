import styles from "../styles/Forms.module.scss"
import MaskedInput from 'react-text-mask';
import { useState, useEffect } from "react";

const Forms = ({validFunction}) => {

  const [firstName, setFirstName] = useState('')
  const [firstNameDirty, setFirstNameDirty] = useState('')
  const [firstNameError, setFirstNameError] = useState('Это поле обязательно')
  const [lastName, setLastName] = useState('')
  const [lastNameDirty, setLastNameDirty] = useState('')
  const [lastNameError, setLastNameError] = useState('Это поле обязательно')
  const [phone, setPhone] = useState('')
  const [phoneDirty, setPhoneDirty] = useState('')
  const [phoneError, setPhoneError] = useState('Это поле обязательно')
  const [email, setEmail] = useState('')
  const [emailDirty, setEmailDirty] = useState('')
  const [emailError, setEmailError] = useState('Это поле обязательно')
  const [address, setAddress] = useState('')
  const [addressDirty, setAddressDirty] = useState('')
  const [addressError, setAddressError] = useState('Это поле обязательно')
  const phoneMask = ['+', '7','(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]
  const [isFormValid, setIsFormValid] = useState(true)

  const blurHandler = (e) => {
    switch (e.target.name) {
      case 'firstName':
        setFirstNameDirty(true)
        break
      case 'lastName':
        setLastNameDirty(true)
        break
      case 'phone':
        setPhoneDirty(true)
        break
      case 'email':
        setEmailDirty(true)
        break
      case 'address':
      setAddressDirty(true)
      break
      default:
        break
    }
  }

  const firstNameHandler = (e) => {
    setFirstName(e.target.value)
    if (e.target.value.length > 15) {
      setFirstNameError('Имя слишком длинное')
    } 
    if (e.target.value.length === 0) {
      setFirstNameError('Это поле обязательно')
    }
    if(e.target.value.length !== 0 && e.target.value.length < 15) {
      setFirstNameError('')
    }
  }

  const lastNameHandler = (e) => {
    setLastName(e.target.value)
    if (e.target.value.length > 15) {
      setLastNameError('Имя слишком длинное')
    } 
    if(e.target.value.length === 0) {
      setLastNameError('Это поле обязательно')
    }
    if (e.target.value.length !== 0 && e.target.value.length < 15) {
      setLastNameError('')
    }
  }
  
  const phoneHandler = (e) => {
    setPhone(e.target.value)
    if (e.target.value.length === 0) {
      setPhoneError('Это поле обязательно')
    } else {
      setPhoneError('')
    }
  }
  
  const emailHandler = (e) => {
    setEmail(e.target.value)
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!re.test(String(e.target.value).toLocaleLowerCase())) {
      setEmailError('Некорректный e-mail')
    }
    if (e.target.value.length === 0) {
      setEmailError('Это поле обязательно')
    }
    if (e.target.value.length !== 0 && re.test(String(e.target.value).toLocaleLowerCase())) {
      setEmailError('')
    }
  }

  const addressHandler = (e) => {
    setAddress(e.target.value)
    if (e.target.value.length === 0) {
      setAddressError('Это поле обязательно')
    } else {
      setAddressError('')
    }
  }
  
  useEffect(() => {
    if (lastNameError || firstNameError || emailError || phoneError) {
      setIsFormValid(true)
      validFunction(isFormValid)
    } else {
      setIsFormValid(false)
      validFunction(isFormValid)
    }
  }, [lastNameError, firstNameError, emailError, phoneError, isFormValid, validFunction])

  return (
    <>
      <div className={styles.inputContainer}>
        <div className={styles.inputNameContainer}>
        <p className={styles.inputName}>
          Имя
        </p>
        {(firstNameDirty && firstNameError) && <p className={styles.inputError}>{firstNameError}</p>}
        </div>
        <input
          value={firstName}
          onChange={e => firstNameHandler(e)}
          onBlur={e => blurHandler(e)}
          type="text"
          name="firstName"
          className={styles.input}
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputNameContainer}>
        <p className={styles.inputName}>
          Фамилия
        </p>
        {(lastNameDirty && lastNameError) && <p className={styles.inputError}>{lastNameError}</p>}
        </div>
        <input
          value={lastName}
          onChange={e => lastNameHandler(e)} 
          onBlur={e => blurHandler(e)}
          type="text"
          name="lastName"
          className={styles.input}
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputNameContainer}>
        <p className={styles.inputName}>
          Телефон
        </p>
        {(phoneDirty && phoneError) && <p className={styles.inputError}>{phoneError}</p>}
        </div>
        <MaskedInput
          className={styles.input}
          mask={phoneMask}
          value={phone}
          type="phone"
          name="phone"
          id="phone"
          onChange={e => phoneHandler(e)} 
          onBlur={e => blurHandler(e)}
          placeholder={"+7(___)___-__-__"}
        />
        {/* <input value={phone} onBlur={e => blurHandler(e)} onChange={e => phoneHandler(e)}  type="text" name="phone" className={styles.input}/> */}
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputNameContainer}>
        <p className={styles.inputName}>
          Электронная почта
        </p>
        {(emailDirty && emailError) && <p className={styles.inputError}>{emailError}</p>}
        </div>
        <input
          value={email}
          onChange={(e) => emailHandler(e)}
          onBlur={e => blurHandler(e)}
          type="text"
          name="email"
          className={styles.input}
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputNameContainer}>
        <p className={styles.inputName}>
          Адрес доставки:
        </p>
        {(addressDirty && addressError) && <p className={styles.inputError}>{addressError}</p>}
        </div>
        <input
          value={address}
          onChange={(e) => addressHandler(e)}
          onBlur={e => blurHandler(e)}
          type="text"
          name="address"
          className={styles.input}
        />
      </div>
    </>
  )
}

export {Forms};