import styles from "../styles/Order.module.scss"
import Arrow from "../images/checkbox.svg"
import emailjs from 'emailjs-com';
import { useState } from "react";
import { OrderLoadingModal } from "./modal/OrderLoadingModal";
import { Forms } from "./Forms";
import ReCAPTCHA from "react-google-recaptcha";

const Order = () => {

  const key = '6Ld9ybYoAAAAAKodYOh24WJC1p2MVHb6FtbGW6I8'
  const key2 = '6Le9w78oAAAAAECea2j6jRBJfvY6opICD5_2TO5u'
  const localKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'

  const [isDisabled, setIsDisabled] = useState(true)
  const [modalLoading, setModalLoading] = useState(false)
  const [checkboxArray, setCheckboxArray] = useState([])
  const [isComplete, setIsComplete] = useState(false)
  const [isCaptcha, setIsCaptcha] = useState(true)

  const [isCheckbox1, setIsCheckbox1] = useState(true)
  const [isCheckbox2, setIsCheckbox2] = useState(true)
  const [isCheckbox3, setIsCheckbox3] = useState(true)
  const [isCheckbox4, setIsCheckbox4] = useState(true)
  const [isCheckbox5, setIsCheckbox5] = useState(true)
  const [isCheckbox6, setIsCheckbox6] = useState(true)
  const [isCheckbox7, setIsCheckbox7] = useState(true)
  const [isCheckbox8, setIsCheckbox8] = useState(true)
  const [isCheckbox9, setIsCheckbox9] = useState(true)
  const [isCheckbox10, setIsCheckbox10] = useState(true)
  const [isCheckbox11, setIsCheckbox11] = useState(true)
  const [isCheckbox12, setIsCheckbox12] = useState(true)

  const onChange = () => {
    setIsCaptcha(false)
    console.log('change')
  }

  const checkboxSetArray = (e) => {
    let checkboxArr = [...checkboxArray];
    const index = checkboxArr.indexOf(e.target.id)
    if (checkboxArr?.some((x) => x === e.target.id)) {
        checkboxArr.splice(index, 1);
    } else {
        checkboxArr.push(e.target.id);
    }

    setCheckboxArray(checkboxArr);
  }

  const unMask = () => {
    var mask = document.getElementById('phone')
    var withoutMask = mask.value.replace(/[-()\s]/g, '');
    mask.value = withoutMask
  }

  const handleDisabled = (isValid) => {
    if (isValid || checkboxArray.length === 0 
      || isCaptcha
      ) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }

  const sendEmail = (e) => {
    e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
    setModalLoading(true);
    emailjs.sendForm('service_tv31t69', 'template_nk22usd', e.target, 'xFv6xnHHJm9cx44Uc')
    .then((result) => {
      setIsComplete(true)
    }, (error) => {
      console.log(error.text);
    });
  }

  return (
    <>
      <div className={`${styles.orderContainer} container`}>
        <OrderLoadingModal modalLoading={modalLoading} isComplete={isComplete} />
        <div className={styles.orderDescr}>
          <h2 className={styles.descrHeading}>
            Информация
          </h2>
          <ul className={styles.descrList}>
            <li className={styles.descrText}>
              Минимальная сумма заказа должна быть не менее 1000 рублей
            </li>
            <li className={styles.descrText}>
              Подробности о наличии товаров и возможности доставки уточняйте у нашего менеджера
            </li>
            <li className={styles.descrText}>
              Доставка осуществляется в удобное для вас время, согласованное заблаговременно
            </li>
            <li className={styles.descrText}>
              При заказе свыше 50 килограмм предоставляется дополнительная скидка
            </li>
            <li className={styles.descrText}>
              Мы принимаем заказы ежедневно, включая выходные дни
            </li>
            <li className={styles.descrText}>
              Наш менеджер свяжетеся с вами и уточнит детали заказа, в течение 24 часов после подачи заявки
            </li>
          </ul>
        </div>
        <h2 className={styles.orderHeading}>
          Сделать заказ
        </h2>
        <form
          className={styles.form}
          onSubmit={(e) => {
            sendEmail(e)
          }}
        >
          <Forms validFunction={handleDisabled}/>
          <div className={styles.chooseContainer}>
            <h3 className={styles.chooseHeading}>
              Отметьте ваш выбор
            </h3>

            <input onChange={e => {checkboxSetArray(e); setIsCheckbox1(!isCheckbox1)}} name="checkbox1" type="checkbox" id="checkbox1" className={styles.checkbox}/>
            <label htmlFor="checkbox1" className={styles.checkboxContainer}>
              <select disabled={isCheckbox1} className={styles.select} name="select1" id="select1">
                <option value=""></option>
                <option value="5кг">5кг</option>
                <option value="10кг">10кг</option>
                <option value="15кг">15кг</option>
                <option value="25кг">25кг</option>
                <option value="Другой">Другой</option>
              </select>
              <span className={styles.arrowContainer}>
                <img className={styles.arrow} src={Arrow} alt="чекбокс" />
              </span>
              <div className={styles.checkboxLabel}>
                <p className={styles.checkboxName}>
                  Сулугуни
                </p>
              </div>
            </label>

            <input onChange={e => {checkboxSetArray(e); setIsCheckbox2(!isCheckbox2)}} name="checkbox2" type="checkbox" id="checkbox2" className={styles.checkbox}/>
            <label htmlFor="checkbox2" className={styles.checkboxContainer}>
              <select disabled={isCheckbox2} className={styles.select} name="select2" id="select2">
                <option value=""></option>
                <option value="5кг">5кг</option>
                <option value="10кг">10кг</option>
                <option value="15кг">15кг</option>
                <option value="25кг">25кг</option>
                <option value="Другой">Другой</option>
              </select>
              <span className={styles.arrowContainer}>
                <img className={styles.arrow} src={Arrow} alt="чекбокс" />
              </span>
              <div className={styles.checkboxLabel}>
                <p className={styles.checkboxName}>
                  Имеретинский
                </p>
              </div>
            </label>  

            <input onChange={e => {checkboxSetArray(e); setIsCheckbox3(!isCheckbox3)}} name="checkbox3" type="checkbox" id="checkbox3" className={styles.checkbox}/>
            <label htmlFor="checkbox3" className={styles.checkboxContainer}>
              <select disabled={isCheckbox3} className={styles.select} name="select3" id="select3">
                <option value=""></option>
                <option value="1кг">1кг</option>
                <option value="5кг">5кг</option>
                <option value="10кг">10кг</option>
                <option value="25кг">25кг</option>
                <option value="Другой">Другой</option>
              </select>
              <span className={styles.arrowContainer}>
                <img className={styles.arrow} src={Arrow} alt="чекбокс" />
              </span>
              <div className={styles.checkboxLabel}>
                <p className={styles.checkboxName}>
                  Чечил молочный
                </p>
              </div>
            </label>

            <input onChange={e => {checkboxSetArray(e); setIsCheckbox4(!isCheckbox4)}} name="checkbox4" type="checkbox" id="checkbox4" className={styles.checkbox}/>
            <label htmlFor="checkbox4" className={styles.checkboxContainer}>
              <select disabled={isCheckbox4} className={styles.select} name="select4" id="select4">
                <option value=""></option>
                <option value="1кг">1кг</option>
                <option value="5кг">5кг</option>
                <option value="10кг">10кг</option>
                <option value="25кг">25кг</option>
                <option value="Другой">Другой</option>
              </select>
              <span className={styles.arrowContainer}>
                <img className={styles.arrow} src={Arrow} alt="чекбокс" />
              </span>
              <div className={styles.checkboxLabel}>
                <p className={styles.checkboxName}>
                  Моцарелла
                </p>
              </div>
            </label>

            <input onChange={e => {checkboxSetArray(e); setIsCheckbox5(!isCheckbox5)}} name="checkbox5" type="checkbox" id="checkbox5" className={styles.checkbox}/>
            <label htmlFor="checkbox5" className={styles.checkboxContainer}>
              <select disabled={isCheckbox5} className={styles.select} name="select5" id="select5">
                <option value=""></option>
                <option value="1кг">1кг</option>
                <option value="5кг">5кг</option>
                <option value="10кг">10кг</option>
                <option value="25кг">25кг</option>
                <option value="Другой">Другой</option>
              </select>
              <span className={styles.arrowContainer}>
                <img className={styles.arrow} src={Arrow} alt="чекбокс" />
              </span>
              <div className={styles.checkboxLabel}>
                <p className={styles.checkboxName}>
                  Рулет сырный
                </p>
              </div>
            </label>

            <input onChange={e => {checkboxSetArray(e); setIsCheckbox6(!isCheckbox6)}} name="checkbox6" type="checkbox" id="checkbox6" className={styles.checkbox}/>
            <label htmlFor="checkbox6" className={styles.checkboxContainer}>
              <select disabled={isCheckbox6} className={styles.select} name="select6" id="select6">
                <option value=""></option>
                <option value="1кг">1кг</option>
                <option value="5кг">5кг</option>
                <option value="10кг">10кг</option>
                <option value="25кг">25кг</option>
                <option value="Другой">Другой</option>
              </select>
              <span className={styles.arrowContainer}>
                <img className={styles.arrow} src={Arrow} alt="чекбокс" />
              </span>
              <div className={styles.checkboxLabel}>
                <p className={styles.checkboxName}>
                  Ассорти рулетов
                </p>
              </div>
            </label>

            <input onChange={e => {checkboxSetArray(e); setIsCheckbox7(!isCheckbox7)}} name="checkbox7" type="checkbox" id="checkbox7" className={styles.checkbox}/>
            <label htmlFor="checkbox7" className={styles.checkboxContainer}>
              <select disabled={isCheckbox7} className={styles.select} name="select7" id="select7">
                <option value=""></option>
                <option value="1кг">1кг</option>
                <option value="5кг">5кг</option>
                <option value="10кг">10кг</option>
                <option value="25кг">25кг</option>
                <option value="Другой">Другой</option>
              </select>
              <span className={styles.arrowContainer}>
                <img className={styles.arrow} src={Arrow} alt="чекбокс" />
              </span>
              <div className={styles.checkboxLabel}>
                <p className={styles.checkboxName}>
                  Сырные блины
                </p>
              </div>
            </label>

            <input onChange={e => {checkboxSetArray(e); setIsCheckbox8(!isCheckbox8)}} name="checkbox8" type="checkbox" id="checkbox8" className={styles.checkbox}/>
            <label htmlFor="checkbox8" className={styles.checkboxContainer}>
              <select disabled={isCheckbox8} className={styles.select} name="select8" id="select8">
                <option value=""></option>
                <option value="1кг">1кг</option>
                <option value="5кг">5кг</option>
                <option value="10кг">10кг</option>
                <option value="25кг">25кг</option>
                <option value="Другой">Другой</option>
              </select>
              <span className={styles.arrowContainer}>
                <img className={styles.arrow} src={Arrow} alt="чекбокс" />
              </span>
              <div className={styles.checkboxLabel}>
                <p className={styles.checkboxName}>
                  Копченный сулугуни
                </p>
              </div>
            </label>

            <input onChange={e => {checkboxSetArray(e); setIsCheckbox9(!isCheckbox9)}} name="checkbox9" type="checkbox" id="checkbox9" className={styles.checkbox}/>
            <label htmlFor="checkbox9" className={styles.checkboxContainer}>
              <select disabled={isCheckbox9} className={styles.select} name="select9" id="select9">
                <option value=""></option>
                <option value="1кг">1кг</option>
                <option value="5кг">5кг</option>
                <option value="10кг">10кг</option>
                <option value="25кг">25кг</option>
                <option value="Другой">Другой</option>
              </select>
              <span className={styles.arrowContainer}>
                <img className={styles.arrow} src={Arrow} alt="чекбокс" />
              </span>
              <div className={styles.checkboxLabel}>
                <p className={styles.checkboxName}>
                  Чечил копченный
                </p>
              </div>
            </label>

            <input onChange={e => {checkboxSetArray(e); setIsCheckbox10(!isCheckbox10)}} name="checkbox10" type="checkbox" id="checkbox10" className={styles.checkbox}/>
            <label htmlFor="checkbox10" className={styles.checkboxContainer}>
              <select disabled={isCheckbox10} className={styles.select} name="select10" id="select10">
                <option value=""></option>
                <option value="1кг">1кг</option>
                <option value="5кг">5кг</option>
                <option value="10кг">10кг</option>
                <option value="25кг">25кг</option>
                <option value="Другой">Другой</option>
              </select>
              <span className={styles.arrowContainer}>
                <img className={styles.arrow} src={Arrow} alt="чекбокс" />
              </span>
              <div className={styles.checkboxLabel}>
                <p className={styles.checkboxName}>
                  Сыр моцарелла копченный
                </p>
              </div>
            </label>

            <input onChange={e => {checkboxSetArray(e); setIsCheckbox11(!isCheckbox11)}} name="checkbox11" type="checkbox" id="checkbox11" className={styles.checkbox}/>
            <label htmlFor="checkbox11" className={styles.checkboxContainer}>
             <select disabled={isCheckbox11} className={styles.select} name="select11" id="select11">
                <option value=""></option>
                <option value="1кг">1кг</option>
                <option value="5кг">5кг</option>
                <option value="10кг">10кг</option>
                <option value="25кг">25кг</option>
                <option value="Другой">Другой</option>
              </select>
              <span className={styles.arrowContainer}>
                <img className={styles.arrow} src={Arrow} alt="чекбокс" />
              </span>
              <div className={styles.checkboxLabel}>
                <p className={styles.checkboxName}>
                  Сырные палочки (мал)
                </p>
              </div>
            </label>

            <input onChange={e => {checkboxSetArray(e); setIsCheckbox12(!isCheckbox12)}} name="checkbox12" type="checkbox" id="checkbox12" className={styles.checkbox}/>
            <label htmlFor="checkbox12" className={styles.checkboxContainer}>
              <select disabled={isCheckbox12} className={styles.select} name="select12" id="select12">
                <option value=""></option>
                <option value="1кг">1кг</option>
                <option value="5кг">5кг</option>
                <option value="10кг">10кг</option>
                <option value="25кг">25кг</option>
                <option value="Другой">Другой</option>
              </select>
              <span className={styles.arrowContainer}>
                <img className={styles.arrow} src={Arrow} alt="чекбокс" />
              </span>
              <div className={styles.checkboxLabel}>
                <p className={styles.checkboxName}>
                  Сырные палочки (бол)
                </p>
              </div>
            </label>

          </div>
          <textarea placeholder="Напишите пожелания к заказу..." className={styles.comment} name="comment" cols="30" rows="10" />
          
          <div className={styles.captchaContainer}>
            <ReCAPTCHA
              sitekey={key2}
              onChange={onChange}
            />
          </div>

          <button
            onClick={() => {
              unMask();
            }}
            disabled={isDisabled}
            className={styles.button}
            type="submit"
          >
            Отправить
          </button>
        </form>
      </div>
    </>
  )
}

export {Order};