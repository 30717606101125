import styles from "../styles/Quality.module.scss"
import quality from "../images/quality.svg"
import master from "../images/qualityMaster.png"
import salt from "../images/salt-quality.jpg"

const Quality = () => {

  return (
    <>
      <div className={`${styles.qualityContainer} container`}>
        <h2 className={styles.heading}>
          Гарантия качества
        </h2>
        <ul className={styles.list}>
          <li className={styles.item}>
            <img className={styles.img} src={quality} alt="" />
            <p className={styles.text}>
              Изготовление нашей продукции основано на использовании только натуральных ингредиентов. Мы имеем свое производство в регионе, известном своими фермерскими хозяйствами, специализирующимися на производстве молока. Независимо от времени года, в процессе производства используется только натуральное молоко. Мы тщательно подбираем лучших поставщиков и устанавливаем высокие стандарты качества молока и условий содержания коров
            </p>
          </li>
          <li className={styles.item}>
            <img className={styles.img} src={master} alt="" />
            <p className={styles.text}>
              За каждым направлением у нас работает сыровар-мастер, который тщательно контролирует весь процесс производства продукции. Для достижения высокого уровня мастерства и получения опыта, мы привлекли лучших специалистов мирового уровня и провели долгую и тщательную работу по изучению технологии сыроварения. Каждую минуту рабочего дня мы придерживаемся и сохраняем исходные технологии и строго соблюдаем традиции приготовления сыров
            </p>
          </li>
          <li className={styles.item}>
            <img className={styles.img} src={salt} alt="" />
            <p className={styles.text}>
              В процессе производства сыров мы используем только один консервант - соль. Этот натуральный консервант несет в себе заботу природы о сохранении продуктов и является безопасным и экологически чистым. Наши сыровары обладают уникальными рецептами, в которых соль максимально раскрывает свои консервирующие свойства, сохраняя продукт в идеальном состоянии и не внося влияние на его изысканный вкус
            </p>
          </li>
        </ul>
      </div>
    </>
  )
}

export {Quality};