import styles from "../../styles/OrderLoadingModal.module.scss"
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const OrderLoadingModal = ({modalLoading, isComplete}) => {

  const scrollToTop = () => {
    window.scrollTo(0,0)
  }

  return (
    <>
      <div className={`${styles.modalLoadingContainer} ${modalLoading ? styles.active : ""}`} >
        {isComplete ? ( 
          <>
            <div className={`${styles.modalLoadingContent} ${modalLoading ? styles.active : ""}`} >
              <h2 className={styles.heading}>
                Заявка отправлена
              </h2>
              <Link onClick={scrollToTop} to={"/"} className={styles.link}>
                На главную
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className={`${styles.modalLoadingContent} ${modalLoading ? styles.active : ""}`} >
              <h2 className={styles.heading}>
                Обрабатываем вашу заявку...
              </h2>
              <ThreeDots 
                height="100" 
                width="80" 
                radius="2px"
                color="var(--red)" 
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible ={true}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export {OrderLoadingModal};