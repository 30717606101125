import { Contacts } from "../components/Contacts";

const ContactsPage = () => {

  return (
    <>
      <Contacts />  
    </>
  )
}

export {ContactsPage};