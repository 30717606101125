import { Link } from "react-router-dom";
import styles from "../styles/KavkazCheese.module.scss"
import Assorti1 from "../images/Assorti_ruletov/Assorti_ruletov1.jfif"
import ChechelMolochniy1 from "../images/Chechel_molochniy/Chechel_molochniy1.jfif"
import ChechelKop from "../images/Chechel_molochniy/Chechel_molochniy2.jfif"
import MotsarellaKop from "../images/MotsarellaKop.png"
import Imeretinskiy1 from "../images/Imeretinskiy/Imeretinskiy1.jfif"
import KopchenyePalochki1 from "../images/Kopchenye_palochki/Kopchenye_palochki1.jfif"
import KopchenyiSuluguni2 from "../images/Kopchenyi_suluguni/Kopchenyi_suluguni2.jfif"
import Motsarella1 from "../images/Motsarella/Motsarella1.jfif"
import RuletSyrniy1 from "../images/Rulet_syrniy/Rulet_syrniy1.jfif"
import Suluguni1 from "../images/Suluguni/suluguni1.jfif"
import TonkiyKopcheniy from "../images/Tonkiy_kopcheniy/Tonkiy_kopcheniy1.jfif"
import Blini from "../images/blini.png"
import ChechilKop from "../images/chechilKop.png"
import { useState } from "react";

const KavkazCheese = () => {

  const [chechelP1, setChechelP1] = useState(true)
  const [chechelP2, setChechelP2] = useState(false)
  const [chechelP3, setChechelP3] = useState(false)
  const [chechelKopP1, setChechelKopP1] = useState(true)
  const [chechelKopP2, setChechelKopP2] = useState(false)
  const [chechelKopP3, setChechelKopP3] = useState(false)
  const [imeretinskiyP1, setImeretinskiyP1] = useState(true)
  const [imeretinskiyP2, setImeretinskiyP2] = useState(false)
  const [imeretinskiyP3, setImeretinskiyP3] = useState(false)
  const [kopchenyePalochkiP1, setKopchenyePalochkiP1] = useState(true)
  const [kopchenyePalochkiP2, setKopchenyePalochkiP2] = useState(false)
  const [kopchenyePalochkiP3, setKopchenyePalochkiP3] = useState(false)
  const [kopchenyiSuluguniP1, setKopchenyiSuluguniP1] = useState(true)
  const [kopchenyiSuluguniP2, setKopchenyiSuluguniP2] = useState(false)
  const [kopchenyiSuluguniP3, setKopchenyiSuluguniP3] = useState(false)
  const [motsarellaP1, setMotsarellaP1] = useState(true)
  const [motsarellaP2, setMotsarellaP2] = useState(false)
  const [motsarellaP3, setMotsarellaP3] = useState(false)
  const [motsarellaKopP1, setMotsarellaKopP1] = useState(true)
  const [motsarellaKopP2, setMotsarellaKopP2] = useState(false)
  const [motsarellaKopP3, setMotsarellaKopP3] = useState(false)
  const [ruletSyrniyP1, setRuletSyrniyP1] = useState(true)
  const [ruletSyrniyP2, setRuletSyrniyP2] = useState(false)
  const [ruletSyrniyP3, setRuletSyrniyP3] = useState(false)
  const [suluguniP1, setSuluguniP1] = useState(true)
  const [suluguniP2, setSuluguniP2] = useState(false)
  const [suluguniP3, setSuluguniP3] = useState(false)
  const [tonkiyKopcheniyP1, setTonkiyKopcheniyP1] = useState(true)
  const [tonkiyKopcheniyP2, setTonkiyKopcheniyP2] = useState(false)
  const [tonkiyKopcheniyP3, setTonkiyKopcheniyP3] = useState(false)
  const [bliniP1, setBliniP1] = useState(true)
  const [bliniP2, setBliniP2] = useState(false)
  const [bliniP3, setBliniP3] = useState(false)

  const [chechelPrice, setChechelPrice] = useState("450")
  const [chechelKopPrice, setChechelKopPrice] = useState("470")
  const [imeretinskiyPrice, setImeretinskiyPrice] = useState("1850")
  const [kopchenyePalochkiPrice, setKopchenyePalochkiPrice] = useState("480")
  const [kopchenyiSuluguniPrice, setKopchenyiSuluguniPrice] = useState("420")
  const [motsarellaPrice, setMotsarellaPrice] = useState("400")
  const [motsarellaKopPrice, setMotsarellaKopPrice] = useState("420")
  const [ruletSyrniyPrice, setRuletSyrniyPrice] = useState("450")
  const [suluguniPrice, setSuluguniPrice] = useState("1850")
  const [tonkiyKopcheniyPrice, setTonkiyKopcheniyPrice] = useState("490")
  const [bliniPrice, setBliniPrice] = useState("450")
  
  const scrollToTop = () => {
    window.scrollTo(0,0)
  }

  return (
    <>
      <div className={`${styles.containerCheese} container`}>
        <h1 className={styles.title}>
          Кавказские сыры
        </h1>
        <ul className={styles.ulCheese}>
        <li className={styles.item}>
            <Link onClick={scrollToTop} className={styles.itemLink} to={"/order"}>
              <img src={Suluguni1} alt="Сулугуни" className={styles.img}/>
            </Link>
            <div className={styles.itemDescr}>
              <div className={styles.descrLeft}>
                <p className={styles.descrTitle}>
                  Сулугуни
                </p>
                <div className={styles.leftWeightContainer}>
                  <span onClick={() => {
                      setSuluguniP1(true)
                      setSuluguniP2(false)
                      setSuluguniP3(false)
                      setSuluguniPrice("1850")
                    }}
                    className={`${styles.weight} ${suluguniP1 ? styles.active : ""}`}
                  >
                    5кг
                  </span>
                  <span onClick={() => {
                      setSuluguniP1(false)
                      setSuluguniP2(true)
                      setSuluguniP3(false)
                      setSuluguniPrice("3700")
                    }} 
                    className={`${styles.weight} ${suluguniP2 ? styles.active : ""}`}
                  >
                    10кг
                  </span>
                  <span onClick={() => {
                      setSuluguniP1(false)
                      setSuluguniP2(false)
                      setSuluguniP3(true)
                      setSuluguniPrice("5550")
                    }} 
                    className={`${styles.weight} ${suluguniP3 ? styles.active : ""}`}
                  >
                    15кг
                  </span>
                </div>
              </div>
              <div className={styles.descrRight}>
                <p className={styles.price}>
                  {suluguniPrice}руб
                </p>
              </div>
            </div>
          </li>
          <li className={styles.item}>
            <Link onClick={scrollToTop} className={styles.itemLink} to={"/order"}>
              <img src={Imeretinskiy1} alt="Имеретинский" className={styles.img}/>
          </Link>
            <div className={styles.itemDescr}>
              <div className={styles.descrLeft}>
                <p className={styles.descrTitle}>
                  Имеретинский
                </p>
                <div className={styles.leftWeightContainer}>
                  <span onClick={() => {
                      setImeretinskiyP1(true)
                      setImeretinskiyP2(false)
                      setImeretinskiyP3(false)
                      setImeretinskiyPrice("1850")
                    }}
                    className={`${styles.weight} ${imeretinskiyP1 ? styles.active : ""}`}
                  >
                    5кг
                  </span>
                  <span onClick={() => {
                      setImeretinskiyP1(false)
                      setImeretinskiyP2(true)
                      setImeretinskiyP3(false)
                      setImeretinskiyPrice("3700")
                    }} 
                    className={`${styles.weight} ${imeretinskiyP2 ? styles.active : ""}`}
                  >
                    10кг
                  </span>
                  <span onClick={() => {
                      setImeretinskiyP1(false)
                      setImeretinskiyP2(false)
                      setImeretinskiyP3(true)
                      setImeretinskiyPrice("5550")
                    }} 
                    className={`${styles.weight} ${imeretinskiyP3 ? styles.active : ""}`}
                  >
                    15кг
                  </span>
                </div>
              </div>
              <div className={styles.descrRight}>
                <p className={styles.price}>
                  {imeretinskiyPrice}руб
                </p>
              </div>
            </div>
          </li>
          <li className={styles.item}>
            <Link onClick={scrollToTop} className={styles.itemLink} to={"/order"}>
              <img src={ChechelMolochniy1} alt="Чечил молочный" className={styles.img}/>
          </Link>
            <div className={styles.itemDescr}>
              <div className={styles.descrLeft}>
                <p className={styles.descrTitle}>
                  Чечил молочный
                </p>
                <div className={styles.leftWeightContainer}>
                  <span onClick={() => {
                      setChechelP1(true)
                      setChechelP2(false)
                      setChechelP3(false)
                      setChechelPrice("450")
                    }}
                    className={`${styles.weight} ${chechelP1 ? styles.active : ""}`}
                  >
                    1кг
                  </span>
                  <span onClick={() => {
                      setChechelP1(false)
                      setChechelP2(true)
                      setChechelP3(false)
                      setChechelPrice("2200")
                    }} 
                    className={`${styles.weight} ${chechelP2 ? styles.active : ""}`}
                  >
                    5кг
                  </span>
                  <span onClick={() => {
                      setChechelP1(false)
                      setChechelP2(false)
                      setChechelP3(true)
                      setChechelPrice("4500")
                    }} 
                    className={`${styles.weight} ${chechelP3 ? styles.active : ""}`}
                  >
                    10кг
                  </span>
                </div>
              </div>
              <div className={styles.descrRight}>
                <p className={styles.price}>
                  {chechelPrice}руб
                </p>
              </div>
            </div>
          </li>
          <li className={styles.item}>
            <Link onClick={scrollToTop} className={styles.itemLink} to={"/order"}>
              <img src={Motsarella1} alt="Моцарелла" className={styles.img}/>
            </Link>
            <div className={styles.itemDescr}>
              <div className={styles.descrLeft}>
                <p className={styles.descrTitle}>
                  Моцарелла
                </p>
                <div className={styles.leftWeightContainer}>
                  <span onClick={() => {
                      setMotsarellaP1(true)
                      setMotsarellaP2(false)
                      setMotsarellaP3(false)
                      setMotsarellaPrice("400")
                    }}
                    className={`${styles.weight} ${motsarellaP1 ? styles.active : ""}`}
                  >
                    1кг
                  </span>
                  <span onClick={() => {
                      setMotsarellaP1(false)
                      setMotsarellaP2(true)
                      setMotsarellaP3(false)
                      setMotsarellaPrice("2000")
                    }} 
                    className={`${styles.weight} ${motsarellaP2 ? styles.active : ""}`}
                  >
                    5кг
                  </span>
                  <span onClick={() => {
                      setMotsarellaP1(false)
                      setMotsarellaP2(false)
                      setMotsarellaP3(true)
                      setMotsarellaPrice("4000")
                    }} 
                    className={`${styles.weight} ${motsarellaP3 ? styles.active : ""}`}
                  >
                    10кг
                  </span>
                </div>
              </div>
              <div className={styles.descrRight}>
                <p className={styles.price}>
                  {motsarellaPrice}руб                  
                </p>
              </div>
            </div>
          </li>
          <li className={styles.item}>
            <Link onClick={scrollToTop} className={styles.itemLink} to={"/order"}>
              <img src={RuletSyrniy1} alt="Сырные рулеты" className={styles.img}/>
            </Link>
            <div className={styles.itemDescr}>
              <div className={styles.descrLeft}>
                <p className={styles.descrTitle}>
                  Рулет сырный
                </p>
                <div className={styles.leftWeightContainer}>
                  <span onClick={() => {
                      setRuletSyrniyP1(true)
                      setRuletSyrniyP2(false)
                      setRuletSyrniyP3(false)
                      setRuletSyrniyPrice("450")
                    }}
                    className={`${styles.weight} ${ruletSyrniyP1 ? styles.active : ""}`}
                  >
                    1кг
                  </span>
                  <span onClick={() => {
                      setRuletSyrniyP1(false)
                      setRuletSyrniyP2(true)
                      setRuletSyrniyP3(false)
                      setRuletSyrniyPrice("2250")
                    }} 
                    className={`${styles.weight} ${ruletSyrniyP2 ? styles.active : ""}`}
                  >
                    5кг
                  </span>
                  <span onClick={() => {
                      setRuletSyrniyP1(false)
                      setRuletSyrniyP2(false)
                      setRuletSyrniyP3(true)
                      setRuletSyrniyPrice("4500")
                    }} 
                    className={`${styles.weight} ${ruletSyrniyP3 ? styles.active : ""}`}
                  >
                    10кг
                  </span>
                </div>
              </div>
              <div className={styles.descrRight}>
                <p className={styles.price}>
                  {ruletSyrniyPrice}руб
                </p>
              </div>
            </div>
          </li>
          <li className={styles.item}>
            <Link onClick={scrollToTop} className={styles.itemLink} to={"/order"}>
              <img src={Assorti1} alt="Ассорти" className={styles.img}/> 
            </Link>
              <div className={styles.itemDescr}>
                <div className={`${styles.descrLeft} ${styles.descrLeftFirst}`}>
                  <p className={styles.descrTitle}>
                    Ассорти рулетов
                  </p>
                  <p className={styles.price}>
                    Цена по запросу
                  </p>
                </div>
              </div>
          </li>
          <li className={styles.item}>
            <Link onClick={scrollToTop} className={styles.itemLink} to={"/order"}>
              <img src={Blini} alt="Сырные блины" className={styles.img}/>
            </Link>
            <div className={styles.itemDescr}>
              <div className={styles.descrLeft}>
                <p className={styles.descrTitle}>
                  Сырные блины
                </p>
                <div className={styles.leftWeightContainer}>
                  <span onClick={() => {
                      setBliniP1(true)
                      setBliniP2(false)
                      setBliniP3(false)
                      setBliniPrice("450")
                    }}
                    className={`${styles.weight} ${bliniP1 ? styles.active : ""}`}
                  >
                    1кг
                  </span>
                  <span onClick={() => {
                      setBliniP1(false)
                      setBliniP2(true)
                      setBliniP3(false)
                      setBliniPrice("2250")
                    }} 
                    className={`${styles.weight} ${bliniP2 ? styles.active : ""}`}
                  >
                    5кг
                  </span>
                  <span onClick={() => {
                      setBliniP1(false)
                      setBliniP2(false)
                      setBliniP3(true)
                      setBliniPrice("4500")
                    }} 
                    className={`${styles.weight} ${bliniP3 ? styles.active : ""}`}
                  >
                    10кг
                  </span>
                </div>
              </div>
              <div className={styles.descrRight}>
                <p className={styles.price}>
                  {bliniPrice}руб
                </p>
              </div>
            </div>
          </li>
          <li className={styles.item}>
            <Link onClick={scrollToTop} className={styles.itemLink} to={"/order"}>
              <img src={KopchenyiSuluguni2} alt="Копченный сулугуни" className={styles.img}/>
          </Link>
            <div className={styles.itemDescr}>
              <div className={styles.descrLeft}>
                <p className={styles.descrTitle}>
                  Копченный сулугуни
                </p>
                <div className={styles.leftWeightContainer}>
                  <span onClick={() => {
                      setKopchenyiSuluguniP1(true)
                      setKopchenyiSuluguniP2(false)
                      setKopchenyiSuluguniP3(false)
                      setKopchenyiSuluguniPrice("420")
                    }}
                    className={`${styles.weight} ${kopchenyiSuluguniP1 ? styles.active : ""}`}
                  >
                    1кг
                  </span>
                  <span onClick={() => {
                      setKopchenyiSuluguniP1(false)
                      setKopchenyiSuluguniP2(true)
                      setKopchenyiSuluguniP3(false)
                      setKopchenyiSuluguniPrice("2050")
                    }} 
                    className={`${styles.weight} ${kopchenyiSuluguniP2 ? styles.active : ""}`}
                  >
                    5кг
                  </span>
                  <span onClick={() => {
                      setKopchenyiSuluguniP1(false)
                      setKopchenyiSuluguniP2(false)
                      setKopchenyiSuluguniP3(true)
                      setKopchenyiSuluguniPrice("4100")
                    }} 
                    className={`${styles.weight} ${kopchenyiSuluguniP3 ? styles.active : ""}`}
                  >
                    10кг
                  </span>
                </div>
              </div>
              <div className={styles.descrRight}>
                <p className={styles.price}>
                  {kopchenyiSuluguniPrice}руб
                </p>
              </div>
            </div>
          </li>
          <li className={styles.item}>
            <Link onClick={scrollToTop} className={styles.itemLink} to={"/order"}>
              <img src={ChechelKop} alt="Чечил копченный" className={styles.img}/>
          </Link>
            <div className={styles.itemDescr}>
              <div className={styles.descrLeft}>
                <p className={styles.descrTitle}>
                  Чечил копченный
                </p>
                <div className={styles.leftWeightContainer}>
                  <span onClick={() => {
                      setChechelKopP1(true)
                      setChechelKopP2(false)
                      setChechelKopP3(false)
                      setChechelKopPrice("470")
                    }}
                    className={`${styles.weight} ${chechelKopP1 ? styles.active : ""}`}
                  >
                    1кг
                  </span>
                  <span onClick={() => {
                      setChechelKopP1(false)
                      setChechelKopP2(true)
                      setChechelKopP3(false)
                      setChechelKopPrice("2300")
                    }} 
                    className={`${styles.weight} ${chechelKopP2 ? styles.active : ""}`}
                  >
                    5кг
                  </span>
                  <span onClick={() => {
                      setChechelKopP1(false)
                      setChechelKopP2(false)
                      setChechelKopP3(true)
                      setChechelKopPrice("4600")
                    }} 
                    className={`${styles.weight} ${chechelKopP3 ? styles.active : ""}`}
                  >
                    10кг
                  </span>
                </div>
              </div>
              <div className={styles.descrRight}>
                <p className={styles.price}>
                  {chechelKopPrice}руб
                </p>
              </div>
            </div>
          </li>
           <li className={styles.item}>
            <Link onClick={scrollToTop} className={styles.itemLink} to={"/order"}>
              <img src={MotsarellaKop} alt="Моцарелла" className={styles.img}/>
            </Link>
            <div className={styles.itemDescr}>
              <div className={styles.descrLeft}>
                <p className={styles.descrTitle}>
                  Моцарелла копченный
                </p>
                <div className={styles.leftWeightContainer}>
                  <span onClick={() => {
                      setMotsarellaKopP1(true)
                      setMotsarellaKopP2(false)
                      setMotsarellaKopP3(false)
                      setMotsarellaKopPrice("420")
                    }}
                    className={`${styles.weight} ${motsarellaKopP1 ? styles.active : ""}`}
                  >
                    1кг
                  </span>
                  <span onClick={() => {
                      setMotsarellaKopP1(false)
                      setMotsarellaKopP2(true)
                      setMotsarellaKopP3(false)
                      setMotsarellaKopPrice("2100")
                    }} 
                    className={`${styles.weight} ${motsarellaKopP2 ? styles.active : ""}`}
                  >
                    5кг
                  </span>
                  <span onClick={() => {
                      setMotsarellaKopP1(false)
                      setMotsarellaKopP2(false)
                      setMotsarellaKopP3(true)
                      setMotsarellaKopPrice("4200")
                    }} 
                    className={`${styles.weight} ${motsarellaKopP3 ? styles.active : ""}`}
                  >
                    10кг
                  </span>
                </div>
              </div>
              <div className={styles.descrRight}>
                <p className={styles.price}>
                  {motsarellaKopPrice}руб                  
                </p>
              </div>
            </div>
          </li>
          <li className={styles.item}>
            <Link onClick={scrollToTop} className={styles.itemLink} to={"/order"}>
              <img src={TonkiyKopcheniy} alt="Сырные палочки мал" className={styles.img}/>
            </Link>
            <div className={styles.itemDescr}>
              <div className={styles.descrLeft}>
                <p className={styles.descrTitle}>
                  Сырные палочки (мал)
                </p>
                <div className={styles.leftWeightContainer}>
                  <span onClick={() => {
                      setTonkiyKopcheniyP1(true)
                      setTonkiyKopcheniyP2(false)
                      setTonkiyKopcheniyP3(false)
                      setTonkiyKopcheniyPrice("490")
                    }}
                    className={`${styles.weight} ${tonkiyKopcheniyP1 ? styles.active : ""}`}
                  >
                    1кг
                  </span>
                  <span onClick={() => {
                      setTonkiyKopcheniyP1(false)
                      setTonkiyKopcheniyP2(true)
                      setTonkiyKopcheniyP3(false)
                      setTonkiyKopcheniyPrice("2450")
                    }} 
                    className={`${styles.weight} ${tonkiyKopcheniyP2 ? styles.active : ""}`}
                  >
                    5кг
                  </span>
                  <span onClick={() => {
                      setTonkiyKopcheniyP1(false)
                      setTonkiyKopcheniyP2(false)
                      setTonkiyKopcheniyP3(true)
                      setTonkiyKopcheniyPrice("4900")
                    }} 
                    className={`${styles.weight} ${tonkiyKopcheniyP3 ? styles.active : ""}`}
                  >
                    10кг
                  </span>
                </div>
              </div>
              <div className={styles.descrRight}>
                <p className={styles.price}>
                  {tonkiyKopcheniyPrice}руб
                </p>
              </div>
            </div>
          </li>
          <li className={styles.item}>
            <Link onClick={scrollToTop} className={styles.itemLink} to={"/order"}>
              <img src={KopchenyePalochki1} alt="Сырные палочки бол" className={styles.img}/>
          </Link>
            <div className={styles.itemDescr}>
              <div className={styles.descrLeft}>
                <p className={styles.descrTitle}>
                  Сырные палочки (бол)
                </p>
                <div className={styles.leftWeightContainer}>
                  <span onClick={() => {
                      setKopchenyePalochkiP1(true)
                      setKopchenyePalochkiP2(false)
                      setKopchenyePalochkiP3(false)
                      setKopchenyePalochkiPrice("480")
                    }}
                    className={`${styles.weight} ${kopchenyePalochkiP1 ? styles.active : ""}`}
                  >
                    1кг
                  </span>
                  <span onClick={() => {
                      setKopchenyePalochkiP1(false)
                      setKopchenyePalochkiP2(true)
                      setKopchenyePalochkiP3(false)
                      setKopchenyePalochkiPrice("2350")
                    }} 
                    className={`${styles.weight} ${kopchenyePalochkiP2 ? styles.active : ""}`}
                  >
                    5кг
                  </span>
                  <span onClick={() => {
                      setKopchenyePalochkiP1(false)
                      setKopchenyePalochkiP2(false)
                      setKopchenyePalochkiP3(true)
                      setKopchenyePalochkiPrice("4700")
                    }} 
                    className={`${styles.weight} ${kopchenyePalochkiP3 ? styles.active : ""}`}
                  >
                    10кг
                  </span>
                </div>
              </div>
              <div className={styles.descrRight}>
                <p className={styles.price}>
                  {kopchenyePalochkiPrice}руб
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}

export {KavkazCheese};